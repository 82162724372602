.clearfix:after,
#cookiebox .holder .popup.settings .content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

#cookiebox {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

#cookiebox .holder {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    height: 100%;
    width: 100%;
    z-index: 10002;
}

#cookiebox .holder .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    background: #fff;
    padding: 30px;
    max-width: 500px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease
}

#cookiebox .holder .popup .logo,
#cookiebox .holder .popup h2 {
    display: inline-block
}

#cookiebox .holder .popup h2 {
    font-size: 26px;
    line-height: 50px;
    margin: 0;
}

#cookiebox .holder .popup .top {
    position: relative;
}

#cookiebox .holder .popup .top .logo {
    max-height: 50px;
    position: absolute;
    top: 50%;
    right: 0;

    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

#cookiebox .holder .popup .top .logo img {
    max-height: 50px;
    max-width: 200px;
}

#cookiebox .holder .popup .content {
    margin-top: 15px
}

#cookiebox .holder .popup .content a {
    text-decoration: underline;
}

#cookiebox .holder .popup .buttons {
    margin-top: 15px
}

#cookiebox .holder .popup .buttons .btn {
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    border: 1px solid #122158;
    padding-right: 30px;
    margin-right: 30px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    border-top-left-radius: 5px
}

#cookiebox .holder .popup .buttons .btn:after {
    content: '\00BB';
    position: absolute;
    background: #122158;
    top: -1px;
    right: -15px;
    width: 30px;
    height: calc(100% + 2px);
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    border-top-right-radius: 5px
}

#cookiebox .holder .popup.settings {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0
}

#cookiebox .holder .popup.settings h2 {
    margin: 0;
    line-height: 1;
}

#cookiebox .holder .popup.settings form label {
    display: block;
    margin-top: 15px;
}

#cookiebox .holder .popup.settings form label input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    outline: none;
    height: 15px;
    width: 15px;
    margin-right: 3px;
    min-height: auto;
    padding: 0;
    display: inline-block;
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    transform: translateY(2px);

    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

#cookiebox .holder .popup.settings form label input:before,
#cookiebox .holder .popup.settings form label input:after {
    content: ' ';
    position: absolute;
    height: 3px;
    background: #FFF;
    border-radius: 2px;

    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}
#cookiebox .holder .popup.settings form label input:before {
    top: 5px;
    left: 3px;
    width: 8px;

    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#cookiebox .holder .popup.settings form label input:after {
    width: 5px;
    top: 6px;
    left: 2px;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

#cookiebox .holder .popup.settings form label input:checked {
    border-color: #122158;
    background: #122158;
}

#cookiebox .holder .popup.settings form label .title {
    font-size: 14px;
    font-weight: bold
}

#cookiebox .holder .popup.settings form label .label {
    position: static;
    color: #FFF;
    padding: 3px 5px;
    font-size: 10px;
    background: #090
}
